import { get } from "lodash";
import React, { useContext } from "react";

import { HomeFlowButton } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import { handleHistoryPush, handleHistoryReplace } from "../../utils/history";
import { GaEvents, sendGAEvent } from "../../utils/googleAnalytics";
import { ContentContext, MainContext } from "../../context";
import GalleryIcon from "./icons/GalleryIcon";

// 'Designs'
export function LibraryFlow(props) {
  const { isPreview, onSelectRipple, isOneMacaron, isMacaronFlow, data: {home} = {} } = props;
  const btn = (home && home.buttons && home.buttons.snap) || (home && home.buttons && home.buttons.library) || {};
  const btnText = (home && home.buttons && home.buttons.library) || {};
  const numberOfButtons = get(props, "extra.numberOfButtons");
  const iconsColor = get(props, "data.general.colors.iconsColor", {});

  const { content } = useContext(ContentContext);
  const { main, setMain } = useContext(MainContext);

  const gridColumn = numberOfButtons % 2 > 0 ? 3 : 0;

  function gotoLibrary() {
    setMain({ ...main, mode: "library" });

    sendGAEvent(GaEvents.FlowSelection, {
      eventCategory: "flow",
      eventLabel: `${isMacaronFlow ? 'Macaron' : 'Drink'}_from_design`,
      value: `${isMacaronFlow ? 'Macaron' : 'Drink'}_from_design`,
      locationId: content.locationId,
    });

    if (isPreview) {
      handleHistoryReplace(props.history, "/library", {
        iFrame: props.iFrame,
        isOneMacaron
      });
    } else {
      handleHistoryPush(props.history, "/library", {
        iFrame: props.iFrame,
        isOneMacaron
      });
    }
  }

  return (
    <HomeFlowButton id="openlibrary"
      onClick={onSelectRipple || gotoLibrary}
      data={{
        ...btn,
        gridColumn,
      }}
      extra={btn}
    >
      <GalleryIcon fill={iconsColor}/>
      <Phrase Key={btnText.translationKey} default={btnText.text || ""} />
    </HomeFlowButton>
  );
}
