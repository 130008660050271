import React, { useContext, useEffect, useState } from "react";
import { MainWrapper, HeaderWrapper, IntroWrapper, LogoWrapper, Header, LogoSpan, MainHomeIntro, Print, PrintedTitle, InstructionText } from "./styles/IntroStyle";
import { LoginButtonComponent } from "./common/LoginButton";
import { ContentContext, MainContext } from "../context";
import { useLoadLibrary, useTheme } from "../services/hooks";
import { handleHistoryPush, handleHistoryReplace } from "../utils/history";
import { Phrase } from "./Phrase";
import { PopUp } from "./popup/PopUp";
import { ActionButtons } from "./home/index";
import { BadLocationMsg } from "./messages/BadLocationMsg";
import FullScreenLoader from "./FullScreenLoader";

import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";
import BackButton from "./home/BackButton";
import PrintSelection from "./PrintSelection";
import LocationLabel from "./home/LocationLabel";
import { BackBtn } from "./styles/commonStyle";

export default function Home(props) {
  useTheme(props);

  function onPopstate(e) {
    const { iFrame } = (props.location && props.location.state) || {};
    if (iFrame) {
      e.preventDefault();
      return;
    }
    handleHistoryReplace(props.history, "/");
  }

  const [isOneMacaron, setIsOneMacaron] = useState(false);
  const [isDrinkFlow, setIsDrinkFlow] = useState(false);

  const { iFrame, withCode } = (props.location && props.location.state) || {};
  const { ripples, surprise } = useLoadLibrary(props);
  const { content } = useContext(ContentContext);
  const { main } = useContext(MainContext);
  const { home: contentLoaded, locationName: locationObj } = content || {};

  useEffect(() => {
    window.addEventListener("popstate", onPopstate);

    return () => window.removeEventListener("popstate", onPopstate);
  }, []);

  // load google analytics
  useEffect(() => {
    if (!contentLoaded) return;
    sendGAEvent(GaEvents.OpenLocation, {
      eventCategory: "locations",
      eventLabel: content.locationId,
      value: `${locationName}, ${content.locationId}`,
      locationId: content.locationId,
    });
    sendGAEvent(GaEvents.OpenLocationName, {
      eventCategory: "locations",
      eventLabel: locationName,
      locationId: content.locationId,
    });

    if (iFrame) {
      const message = JSON.stringify({
        status: "loaded",
        type: "document",
      });
      window.parent.postMessage(message, "*");
    }
  }, [contentLoaded]);

  if (!contentLoaded) {
    return <FullScreenLoader />;
  }

  const { isMacaronsEnabled } = (content.general && content.general.workflows) || (content.configuration && content.configuration.features) || {};

  const locationName = locationObj ? (locationObj.displayName ? locationObj.displayName : locationObj.name ? locationObj.name : "") : "";

  const general = content.general;
  const { instruction1, instruction2, LetsStartText: printLetsStart = {}, createOrChooseDesign = {}, back = {} } = content.home.texts.instructions || {};
  const { mainImage, logo } = general.images || {};
  const { backgroundColor } = general.colors || {};
  const { defaultEditMode } = general.workflows || {};

  function onImageError(id, newsrc) {
    const elem = document.getElementById(id);
    elem.onerror = null;
    elem.src = newsrc;
  }

  //set background color
  let overlay = document.getElementById("root");
  overlay.setAttribute("style", `background-color:${backgroundColor}`);

  const handleBackClick = () => {
    setIsDrinkFlow(false);
    setIsOneMacaron(false);
  };

  const handleCreateText = (targetScreen) => {
    sendGAEvent(GaEvents.FlowSelection, {
      eventCategory: "flow",
      eventLabel: `${isOneMacaron ? "Macaron" : "Drink"}_create_by_myself`,
      value: `${isOneMacaron ? "Macaron" : "Drink"}_create_by_myself`,
      locationId: content.locationId,
    });

    handleHistoryPush(props.history, "/edit", {
      iFrame: props.iFrame,
      reactNativeFlow: props.location.state.reactNativeFlow,
      isOneMacaron,
      isCreateByMyself: true,
      target: { screen: targetScreen ? targetScreen : defaultEditMode === "texts" ? defaultEditMode : undefined },
    });
  };

  const handleSelectOneMacaron = () => {
    setIsOneMacaron(true);
  };

  const handleDrinkFlowClick = () => {
    sendGAEvent(GaEvents.CanvasSelection, {
      eventCategory: "flow",
      eventLabel: "Drink",
      value: "Drink",
      locationId: content.locationId,
    });

    setIsDrinkFlow(true);
  };

  const handleBack = () => {
    handleHistoryPush(props.history, "/");
  }

  const getTitle = () => {
    if (isOneMacaron || isDrinkFlow) return createOrChooseDesign;

    return printLetsStart;
  };

  const title = getTitle();

  return (
    <MainWrapper id="main">
      <HeaderWrapper>
        {!iFrame && <LoginButtonComponent colors={{ main: printLetsStart.color, backgroundColor }} {...props} />}
        {!iFrame && !main.preview && !withCode && <BackBtn onClick={handleBack} style={{position: 'fixed', top: '20px', left: '10px', zIndex: 1000000}} svgStyle={{fill: general.colors.generalTextColor, stroke: general.colors.backgroundColor, strokeWidth: '1px', strokeLinejoin: 'round', paintOrder: 'stroke'}}/>}
        {mainImage && mainImage.location && <Header id="hdr" location={mainImage.location} size={mainImage.size} />}
        {logo && logo.location && (
          <LogoWrapper>
            <LogoSpan width={logo.width}>
              <img
                src={logo.location}
                alt=""
                crossOrigin="anonymous"
                id="homelogo"
                onError={() => {
                  onImageError("homelogo", logo.location);
                }}
              />
            </LogoSpan>
          </LogoWrapper>
        )}
      </HeaderWrapper>

      {isMacaronsEnabled && !isOneMacaron && !isDrinkFlow ? (
        <PrintSelection content={content} onSelectOneMacaron={handleSelectOneMacaron} onDrinkFlowClick={handleDrinkFlowClick} history={props.history} locationName={locationName} />
      ) : (
        <MainHomeIntro id="mainIntro">
          <IntroWrapper id="introwrapper" color={backgroundColor}>
            <Print id="print">
              <PrintedTitle id="printed" data={title} style={{ width: "80%" }}>
                <Phrase Key={title.translationKey} default={title.text || ""} />
              </PrintedTitle>
              {!isOneMacaron && !isDrinkFlow && (
                <>
                  <InstructionText id="picor" data={instruction1}>
                    <Phrase Key={instruction1.translationKey} default={instruction1.text} />
                  </InstructionText>
                  <InstructionText id="picor2" data={instruction2} extra={instruction1}>
                    <Phrase Key={instruction2.translationKey} default={instruction2.text} />
                  </InstructionText>
                </>
              )}
            </Print>

            <ActionButtons
              iFrame={iFrame}
              content={content}
              ripples={ripples}
              surprise={surprise}
              {...props}
              isPreview={main.preview}
              isOneMacaron={isOneMacaron}
              onCreateText={handleCreateText}
            />
            {(isDrinkFlow || isOneMacaron) && <BackButton onBackClick={handleBackClick} textColor={general.colors.generalTextColor} backText={back} />}
            {locationName && <LocationLabel textColor={general.colors.generalTextColor} locationName={locationName} />}
          </IntroWrapper>
        </MainHomeIntro>
      )}
      {!iFrame && !content.mobileq && <BadLocationMsg data={content.messages.texts.badLocation} back={general.colors} />}
      {!iFrame && !main.ripplesPolicy && !main.preview && <PopUp privacy={true} />}
    </MainWrapper>
  );
}
