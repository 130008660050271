import React, { useContext, useRef } from "react";

import { HomeFlowButton } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import CreateIcon from "./icons/CreateIcon";
import { GaEvents, sendGAEvent } from "../../utils/googleAnalytics";
import { ContentContext, MainContext } from "../../context";

// 'Photo'
export function GalleryFlow(props) {
  const { data, onCreateText, isMacaronFlow } = props;
  const { home, general } = data || {};

  const { main, setMain } = useContext(MainContext);
  const { content } = useContext(ContentContext);

  const btn = (home && home.buttons && home.buttons.snap) || (home && home.buttons && home.buttons.create) || {};
  const btnText = (home && home.buttons && home.buttons.create) || {};
  const iconsColor = (general && general.colors && general.colors.iconsColor) || null

  const fileInput = useRef();

  const inputHandler = (e) => {
    e.persist();

    sendGAEvent(GaEvents.FlowSelection, {
      eventCategory: "flow",
      eventLabel: `${isMacaronFlow ? 'Macaron' : 'Drink'}_create_by_myself`,
      value: `${isMacaronFlow ? 'Macaron' : 'Drink'}_create_by_myself`,
      locationId: content.locationId,
    });

    setMain({ ...main, mode: "gallery" });
    props.onChange(e);
  }

  const openGallery = () => {
    fileInput.current.click();
  }

  return (
    <HomeFlowButton id="opengallery" onClick={onCreateText || openGallery} data={btn} extra={btn}>
      {!onCreateText && <input style={{ display: "none" }} type="file" accept="image/*" onChange={inputHandler} ref={fileInput} />}
      <CreateIcon stroke={iconsColor}/>
      <Phrase Key={btnText.translationKey} default={btnText.text || ""} />
    </HomeFlowButton>
  );
}
